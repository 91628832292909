import image1 from "../assets/t-image1.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Tegin õige valiku selle fotograafi ja programmi valimisega. Saavutatud tulemus oli super!",
    name: 'MATHIAS HENRIKSON',
    status : 'ARENDAJA'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'KEVIN PÄRN',
    status: 'KLIENT'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'FRANK NIINEMETS',
    status: "KLIENT"
  }
];