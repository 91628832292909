import i1 from '../assets/instagram/i1.jpg'
import i2 from '../assets/instagram/i2.jpg'
import i3 from '../assets/instagram/i3.jpg'
import i4 from '../assets/instagram/i4.jpg'

export const programsData = [
    {
  
      image: i1
      
    },

    {
      image: i2
    },

    {
    
      image: i3
      
    },

    {
      
      image: i4

    },
  ];
  
  
  